<template>
  <overlay :show="show" z-index="9999">
    <div class="overlayMain">
      请点击右下方使用完整服务
    </div>
  </overlay>
</template>
<script>
import { Overlay  } from 'vant'
export default {
  components: {
    Overlay
  },
  data () {
    return {
      show: false
    }
  },
  watch:{
  '$store.state.user_type':{
    handler: function (newVal,oldVal) {
      if (newVal == 1) {
        this.show = true
      } else {
        this.show = false
      }
      },
    deep: true
    }
  },
}
</script>
<style scoped>
.overlayMain {
  color: #ddd;
  font-size: 16px;
  height: 100%;
  width: 100%;
  display: grid ;
  align-items: center;
  justify-content: center;
}
</style>
