import Vue from 'vue'
import VueRouter from 'vue-router'
import storage from 'store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index/index'),
  },
  // 任务宝
  {
    path: '/workFission',
    name: 'workFissionIndex',
    meta: {
      title: ' '
    },
    component: () => import(/* webpackChunkName: "workFission" */ '@/views/workFission/index'),
  },
  {
    path: '/workFission/speed',
    component: () => import(/* webpackChunkName: "workFission" */ '@/views/workFission/speed'),
  },
  // 抽奖活动
  {
    path: '/lottery',
    name: 'lotteryIndex',
    meta: {
      title: '抽奖活动'
    },
    component: () => import(/* webpackChunkName: "lottery" */ '@/views/lottery/index'),
  },
  // 抽奖活动——规则说明
  {
    path: '/explain',
    component: () => import(/* webpackChunkName: "lottery" */ '@/views/lottery/explain'),
  },
  // 互动雷达
  {
    path: '/radar',
    name: 'radar',
    component: () => import(/* webpackChunkName: "radar" */ '@/views/radar/index'),
  },
  // 群打卡
  {
    path: '/roomClockIn',
    name: 'roomClockIn',
    component: () => import(/* webpackChunkName: "roomClockIn" */ '@/views/roomClockIn/index'),
  },
  // 问卷
  {
    path: '/naireDetail',
    name: 'naireDetail',
    component: () => import(/* webpackChunkName: "questionnaire" */ '@/views/naire/index'),
    meta: {
      // title: '调研问卷'
    },
  },
  // 门店活码
  {
    path: '/shopCode',
    name: 'shopCodeIndex',
    component: () => import(/* webpackChunkName: "shopCode" */ '@/views/shopCode/index'),
  },
  // 群裂变
  {
    path: '/roomFission',
    component: () => import(/* webpackChunkName: "roomFission" */ '@/views/roomFission/index'),
  },
  // 群裂变海报
  {
    path: '/roomFission/poster',
    component: () => import(/* webpackChunkName: "roomFission" */ '@/views/roomFission/poster'),
  },
  // 群裂变——助力进度
  {
    path: '/roomFission/speed',
    component: () => import(/* webpackChunkName: "roomFission" */ '@/views/roomFission/speed'),
  },
  // 无限拉群
  {
    path: '/roomInfinitePull',
    component: () => import(/* webpackChunkName: "roomInfinitePull" */ '@/views/roomInfinitePull/index'),
  },
  // 引流链接
  {
    path: '/drainageLink',
    component: () => import(/* webpackChunkName: "drainageLink" */ '@/views/drainageLink/index'),
  },
  // 优惠券
  {
    path: '/coupon',
    name: 'couponIndex',
    meta: {
      title: '我的卡券'
    },
    component: () => import(/* webpackChunkName: "couponIndex" */ '@/views/coupon/index'),
  },
  // 优惠券详情
  {
    path: '/couponDetail',
    name: 'couponDetail',
    meta: {
      // title: '卡券'
    },
    component: () => import(/* webpackChunkName: "couponDetail" */ '@/views/coupon/detail'),
  },
  {
    path: '/couponWrite',
    name: 'couponWrite',
    meta: {
      title: '核销卡券'
    },
    component: () => import(/* webpackChunkName: "couponWrite" */ '@/views/coupon/write'),
  },
  {
    path: '/articleDetail',
    name: 'articleDetail',
    meta: {
      title: '智慧传播'
    },
    component: () => import(/* webpackChunkName: "articleSpread" */ '@/views/articleSpread/index'),
  },
  {
    path: '/shop',
    name: 'shop',
    meta: {
      title: '商城',
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "articleSpread" */ '@/views/shop/home'),
  },
  {
    path: '/shop/user',
    name: 'shopUser',
    meta: {
      title: '个人中心',
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "shopUser" */ '@/views/shop/user'),
  },
  {
    path: '/shop/cate',
    name: 'shopCate',
    meta: {
      title: '商品分类',
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "shopCate" */ '@/views/shop/cate'),
  },
  {
    path: '/shop/productList',
    name: 'shopProductList',
    meta: {
      title: '商品列表',
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "shopCate" */ '@/views/shop/productList'),
  },
  {
    path: '/shop/detail',
    name: 'shopDetail',
    meta: {
      title: '商品详情'
    },
    component: () => import(/* webpackChunkName: "articleSpread" */ '@/views/shop/detail'),
  },
  {
    path: '/shop/orderList',
    name: 'shopOrderList',
    meta: {
      title: '订单列表',
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "articleSpread" */ '@/views/shop/orderList'),
  },
  {
    path: '/shop/orderDetail',
    name: 'shopOrderDetail',
    meta: {
      title: '订单详情'
    },
    component: () => import(/* webpackChunkName: "orderDetail" */ '@/views/shop/orderDetail'),
  },
  {
    path: '/shop/confirmOrder',
    name: 'shopConfirmOrder',
    meta: {
      title: '确认订单'
    },
    component: () => import(/* webpackChunkName: "articleSpread" */ '@/views/shop/confirmOrder'),
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index'),
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition && to.meta.keepAlive) {
     return savedPosition;
    }
    // 异步滚动操作
    return new Promise((resolve) => {
     setTimeout(() => {
      resolve({ x: 0, y: 1 });
     }, 0);
    });
  }
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title || to.meta.title == ' ') {
    document.title = to.meta.title
  } else {
    document.title = "聆云通"
  }
  // if (check_router.indexOf(to.path) != -1) {
  //   let token = storage.get('ACCESS_TOKEN')
  //   if (!token) {
  //     console.log(this)
  //   }
  // }
  // if(to.params.refresh){
  //   to.meta.keepAlive = false;
  // }
  next()
})

export default router
