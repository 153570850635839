import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import LuckDraw from 'vue-luck-draw'
import wxJssdk from 'jweixin-module'

Vue.use(LuckDraw);
Vue.use(Antd);

import './plugins/ant'
import './plugins/axios'
import './plugins/utils'

Vue.prototype.$themeColor = '#ff8c00'

Vue.config.productionTip = false

document.setTitle = function (t) {
  document.title = t;
  var i = document.createElement("iframe");
  i.src = "./favicon.ico";
  i.style.display = "none";
  i.onload = function () {
    setTimeout(function () {
      i.remove();
    }, 9);
  };
  document.body.appendChild(i);
}

import wechatJs from '@/plugins/wechatJs.js'
Vue.use(wechatJs, Vue)
new Vue({
  router,
  store,
  wxJssdk,
  render: h => h(App)
}).$mount('#app')
