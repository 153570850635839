<template>
  <div id="app">
    <authOverlay></authOverlay>
      <!-- 可以被缓存的视图组件 -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- 不可以被缓存的视图组件 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import authOverlay from "@/components/authOverlay"
export default {
  components: {
    authOverlay
  },
  mounted() {
    this.$message.config({
      top: `40%`
    });
  }
}
</script>

<style lang="less">
*::-webkit-scrollbar {
  display: none;
}
</style>
