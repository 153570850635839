import Vue from "vue";
import axios from "axios";
import message from 'ant-design-vue/es/message'
import { Toast } from 'vant'
import storage from 'store'
import store from '../store'

const request = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL + '/operation',
  // baseURL: '/operation',
  // withCredentials: true,
  timeout: 15000
})

const request_op = axios.create({
  baseURL: process.env.BASE_URL,
  // withCredentials: true,
  timeout: 15000
})

// 请求token判断
const request_check = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL + '/operation',
  // baseURL: '/operation',
  // withCredentials: true,
  timeout: 15000
})

const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    const status = error.response.status
    // message.error(`${status || ''}  ${data.msg || 'error'}`)
    Toast.fail(`${status || ''}  ${data.msg || 'error'}`)
  } else {
    Toast.fail(error.message || '请求出错，请稍后重试！')
    // message.error(error.message || '请求出错，请稍后重试！')
  }
  return Promise.reject(error)
};

const requestInterceptor = (config) => {
  const token = storage.get('ACCESS_TOKEN')
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Accept'] = `application/json`
    config.headers.Authorization = token
  }
  return config
}

request.interceptors.request.use(requestInterceptor, errorHandler)

request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

request_op.interceptors.request.use(requestInterceptor, errorHandler)

request_op.interceptors.response.use((response) => {
  return response.data
}, errorHandler)


const errorHandler_check = (error) => {
  if (error.response) {
    const data = error.response.data
    const status = error.response.status
    
    Toast.fail(`${status || ''}  ${data.msg || 'error'}`)
    
    // 登录失效则跳转授权
    if (data.code >= 100001 && data.code <= 100005) {
      console.log(store._actions.Login[0]())
      location.href = '/login?t_url=' + encodeURIComponent(window.location.pathname + window.location.search)
    }

  } else {
    Toast.fail(error.message || '请求出错，请稍后重试！')
    // message.error(error.message || '请求出错，请稍后重试！')
  }
  return Promise.reject(error)
};

const requestInterceptor_check = (config) => {
  const token = storage.get('ACCESS_TOKEN')
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Accept'] = `application/json`
    config.headers.Authorization = token
  }
  return config
}

request_check.interceptors.request.use(requestInterceptor_check, errorHandler_check)

request_check.interceptors.response.use((response) => {
  console.log()
  if (response.data.code != 200) {
    Toast.fail(response.data.msg || '请求出错，请稍后重试！')
  } else {
    return response.data
  }
}, errorHandler_check)


Vue.prototype.$http = request;

export {request, request_op, request_check};
