
const wechatJs = window.wx

const install = (Vue, vm) => {
  const init = async (params,callback) => {
		// console.log('params',params)
		wechatJs.config({
			debug: false,
			appId: params.appId,
			timestamp: params.timestamp,
			nonceStr: params.nonceStr,
			signature: params.signature,
			jsApiList: params.jsApiList,
			openTagList: params.openTagList
			// jsApiList: [
			// 	'updateAppMessageShareData', 'updateTimelineShareData',
			// 	'onMenuShareAppMessage', 'onMenuShareTimeline',
			// 	'openLocation'
			// ],
			// openTagList: [
			// 	'wx-open-launch-weapp'
			// ]
		});

		wechatJs.ready( function(res) {
			console.log('注入ready',res)
			callback && callback()
			// isState = !error;
			// console.log(isState,error);
			// isState = !error;
		})
		wechatJs.error(function(res){
			
		});
		
		wechatJs.success = (res)=>{
			console.log('注入success',res);
		}
		
		wechatJs.complete = (res)=>{
			console.log('注入complete',res);
		}
	}

  const pay = (params = {}, callback = () => {}, cancel = () => {}, fail = () => {}) => {
		wechatJs.chooseWXPay({
      timestamp: params.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
      nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
      package: params.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
      signType: params.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
      paySign: params.paySign, // 支付签名
      success: function (res) {
        // 支付成功后的回调函数
				callback(res)
      },
			// 支付取消回调函数
			cancel: function (res) {
				cancel(res)
			},
			// 支付失败回调函数
			fail: function (res) {
				fail(res)
			}
    })
  }

  const openLocation = (params = {}) => {
		params.latitude = Number(params.latitude)
		params.longitude = Number(params.longitude)
		wechatJs.openLocation(params);
	}

	const share = (params = {}) => {
		const shareData = {
			title: params?.title || '默认标题',
			desc: params?.desc || '默认描述',
			link: params.link || window.location.href,
			imgUrl: params?.imgUrl ||
				'https://moyioss.oss-cn-shanghai.aliyuncs.com/uploads/20191105/4e8d3acf300b2219547d7dffb256c71e',
			success: params?.success || function (){console.log('未设置分享后操作')}
		}
		
		const shareDataOld = {
			title: params?.title || '默认标题',
			desc: params?.desc || '默认描述',
			link: params.link || window.location.href,
			imgUrl: params?.imgUrl ||
				'https://moyioss.oss-cn-shanghai.aliyuncs.com/uploads/20191105/4e8d3acf300b2219547d7dffb256c71e',
			success: params?.success || function (){console.log('未设置分享后操作')}
		}
		console.log(shareData)
		// console.log('分享链接已设置>', params.link || window.location.href);

			
		//分享给朋友接口
		if(wechatJs.onMenuShareAppMessage){
			wechatJs.onMenuShareAppMessage(shareDataOld);
		}else{
			wechatJs.updateAppMessageShareData(shareData);
		}
		
		//分享到朋友圈接口
		if(wechatJs.onMenuShareTimeline){
			wechatJs.onMenuShareTimeline(shareDataOld);
		}else{
			wechatJs.updateTimelineShareData(shareData);
		}
	}

  Vue.prototype.$wx = {
		init,
    pay,
    openLocation,
		share
	}
}

export default {
	install
}