import {request, request_op} from "../plugins/axios";

//获取微信用户信息
export function openUserInfoApi (params) {
  return request({
    url: '/officialAccount/openUserInfo',
    method: 'GET',
    params: params
  })
}

//登录
export function login (params) {
  return request_op({
    url: '/operation/login/officialAccount',
    method: 'GET',
    params: params
  })
}