import Vue from 'vue'

Vue.prototype.$getQueryVariable = name => {
    const reg = new RegExp(`(^|&)?` + name + `=([^&]*)(&|$)`, `i`);
    const r = window.location.href.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}

Vue.prototype.$getState = (name, url = '') => {
    const newUrl = url ? url : decodeURIComponent(window.location.href);

    const match = newUrl.match(/&state=(.+)/i);

    if (!match) return false;

    const state = match[1].replace(/#\/(.+)/, '');

    const reg = new RegExp(`(^|&)?` + name + `=([^&]*)(&|$)`, `i`);
    if(state.match(reg)==null){
        return ''
    }else{
        return state.match(reg)[2];
    }
}

Vue.prototype.$redirectAuth = (url = '') => {
    if (url === '') {
        return;
    }
    url = window.location.origin + url;
    // url = process.env.VUE_APP_API_BASE_URL + url
    // console.log(url)
    window.location.href = url;
}

// 检测是否链接
Vue.prototype.$checkUrl = (url) => {
    var reg=/^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
    if(reg.test(url)){
        return url;
    }
}