import Vue from 'vue'
import Vuex from 'vuex'
import storage from 'store'
import { login } from '@/api/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    code: '',
    fission_id: 0,
    union_id: '',
    token: '',
    user_type: ''
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER_TYPE: (state, type) => {
      state.user_type = type
    }
  },
  actions: {
    // 登录
    Login ({ commit }, setData) {
      return new Promise((resolve, reject) => {
        let p = {
          cid: setData.cid
        }
        // if(storage.get('uid')){
        //   p.uid = storage.get('uid')
        // }
        login(p).then(response => {
          const result = response.data
          const token = `Bearer ${result.token}`
          const user_type = result.is_snapshotuser
          if (setData.check_code) {
            storage.set('CHECK_CODE', setData.check_code)
          }
          storage.set('cid', setData.cid)
          storage.set('ACCESS_TOKEN', token, new Date().getTime() + result.expire * 1000)
          // storage.set('ACCESS_TOKEN', token, new Date().getTime() + 60 * 1000)
          commit('SET_USER_TYPE', user_type)
          commit('SET_TOKEN', token)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  modules: {
  }
})
